<template>
    <div class="thematicLibraryConfig">
        <el-tabs v-model="activeName">
            <el-tab-pane label="应用数据库" name="1"></el-tab-pane>
            <el-tab-pane label="数据应用目录" name="2"></el-tab-pane>
            <el-tab-pane label="查询接口" name="3"></el-tab-pane>
            <el-tab-pane label="目录扩展属性" name="4"></el-tab-pane>
            <el-tab-pane label="文件信息表配置" name="5"></el-tab-pane>
        </el-tabs>
        <div class="tab-con">
            <div class="tree-con">
                <el-tree
                    :data="treeData"
                    :props="defaultProps"
                    :highlight-current="true"
                    :expand-on-click-node="false"
                    node-key="id"
                    :default-expanded-keys="defaultKey"
                    :current-node-key="currentNodeKey"
                    :default-expand-all="true"
                    class="businessGroupWh-slider-tree"
                    ref="tree"
                >
                    <span class="slot-t-node" slot-scope="{ node, data }">
                        <div class="parent-node" v-if="data.children.length>0">
                            <i
                                v-if="node.expanded"
                                class="iconfont iconshouqi"
                                @click.stop="expandtree(node)"
                            ></i>
                            <i v-if="!node.expanded" class="iconfont iconzhankai" @click.stop="expandtree(node)"></i>
                            <span v-if="node.expanded" class="iconfont iconwenjianjia-zhankai" @click.stop="expandtree(node)"></span>
                            <span v-if="!node.expanded" class="iconfont iconwenjianjia-shouqi" @click.stop="expandtree(node)"></span>
                            <span>{{ data.name }}</span>
                        </div>
                        <div class="leaf-node" v-else>
                            <span class="iconfont iconlingxing"></span>
                            <span>{{ data.name }}</span>
                        </div>
                    </span>
                </el-tree>
            </div>
            <div class="tab-con-main">
                <el-tabs type="border-card" v-model="tableActiveName">
                    <el-tab-pane label="字段配置" name="1">
                        <div class="table-main">
                            <div class="data-table">
                                <el-form :inline="true" class="demo-form-inline">
                                    <el-form-item label="数据库">
                                        <el-input placeholder="数据库" :disabled="true"></el-input>
                                    </el-form-item>
                                    <el-form-item label="数据表">
                                        <el-input placeholder="数据表"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" size="medium">
                                            保存
                                        </el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                            <div class="table-con">
                                <el-table
                                    :data="tableData"
                                    stripe
                                    style="width: 100%"
                                    height="calc(100% - 35px)"
                                    @selection-change="handleSelectionChange"
                                >
                                    >
                                    <el-table-column
                                        type="selection"
                                        width="55"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        label="信息项名称"
                                    >
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.info_name }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="主键"
                                    >
                                        <template slot-scope="scope">
                                            <el-radio
                                                v-model="radio"
                                                :label="scope.row.info_id"
                                                @change.native="getTemplateRow(scope.$index,scope.row)"
                                            >
                                                <i></i>
                                            </el-radio>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="数据字段名称"
                                    >
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.data_name" placeholder="请输入内容"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="数据字段中文名"
                                    >
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.data_name_cn" placeholder="请输入内容"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="数据字段类型"
                                    >
                                        <template slot-scope="scope">
                                            <el-select
                                                v-model="scope.row.data_type"
                                                placeholder="请选择信息项类型"
                                            >
                                                <el-option
                                                    v-for="item in dataTypes"
                                                    :label="item.name_cn"
                                                    :value="item.name"
                                                    :key="item.id"
                                                ></el-option>
                                            </el-select>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="字段长度"
                                    >
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.data_length" placeholder="请输入内容"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="小数位"
                                    >
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.data_scale" placeholder="请输入内容"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="规则"
                                    >
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.data_rule" placeholder="请输入内容"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="说明"
                                    >
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.data_remarks" placeholder="请输入内容"></el-input>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div class="table-page">
                                    <el-pagination
                                        @size-change="handleSizeChange"
                                        @current-change="handleCurrentChange"
                                        :current-page="pagetion.pageindex"
                                        :page-sizes="pagetion.pagesizes"
                                        :page-size="pagetion.pagesize"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="pagetion.total"
                                    >
                                    </el-pagination>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="数据浏览" name="2"></el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            activeName: '1',
            treeData: [
                {
                    name: '应用数据库',
                    children: [
                        {
                            name: '数据表管理',
                            children: [],
                        },
                        {
                            name: '视图管理',
                            children: [
                                {
                                    name: '块数据接口',
                                    children: [
                                        {
                                            name: 'GHSS_YD',
                                            children: [],
                                        },
                                        {
                                            name: 'GHSS_YD',
                                            children: [],
                                        },
                                        {
                                            name: 'GHSS_YD',
                                            children: [],
                                        },
                                        {
                                            name: 'GHSS_YD',
                                            children: [],
                                        },
                                        {
                                            name: 'GHSS_YD',
                                            children: [],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
            defaultProps: {
                children: 'children',
                label: 'name',
            },
            defaultKey: [], // 默认展开节点id集合
            currentNodeKey: '', // 当前节点key
            tableActiveName: '1',
            tableData: [],
            pagetion: {
                pageindex: 1,
                pagesize: 10,
                total: 0,
                pagesizes: [10, 20, 30, 40, 50, 100],
            },
            selectData: [], // 多选选中数据集合
            radio: '',
            dataTypes: [], // 数据字段类型
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 展开节点
        expandtree(node) {
            node.expanded = !node.expanded;
            if (node.expanded) {
                this.defaultKey = [];
                this.defaultKey.push(node.data.id);
            } else {
                this.defaultKey = [];
            }
        },
        // 获取数据字段
        getEntityData() {
            this.$axios
                .get('/interfaceApi/datacenter/metadata/entity/data/edit/getpage/8b98c62a-32c4-44fc-8f3e-1eee8219623b'
                + '?pageindex=' + this.pagetion.pageindex + '&pagesize=' + this.pagetion.pagesize)
                .then(res => {
                    if (res) {
                        this.pagetion.total = res.total;
                        this.tableData = res.rows;
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
    created() {
        this.getEntityData();
    },
    mounted() {},
};
</script>
<style lang="stylus">
.thematicLibraryConfig
    width 100%
    height 100%
    .el-tabs__item
        height 46px
        line-height 46px
        &.is-top
            &:nth-child(2)
                padding-left 20px
    .tab-con
        height calc(100% - 60px)
        overflow hidden
        padding 0 .1rem .1rem .1rem
        .tree-con
            width 300px
            float left
            height 100%
            overflow-y auto
            overflow-x hidden
            border 1px solid #DCDFE6
            .slot-t-node
                line-height .38rem
                height .38rem
                .parent-node
                    .iconzhankai
                        color #5588F1
                    .iconshouqi
                        color #F19F26
                    .iconwenjianjia-shouqi
                        color #5588F1
                    .iconwenjianjia-zhankai
                        color #F19F26
                    .iconfont
                        margin-right .1rem
                .leaf-node
                    color #666666
                    .iconlingxing
                        color #979EA7
                        margin 0 .1rem 0 0
                        font-size .14rem
        .tab-con-main
            width calc(100% - 310px)
            margin-left 10px
            height 100%
            float left
            .el-tabs
                height 100%
            .el-tabs__content
                height calc(100% - 45px)
                .el-tab-pane
                    height 100%
                    .table-main
                        height 100%
                        .table-con
                            height calc(100% - 62px)
                            .el-table
                                border 1px solid #f0f0f0
                                border-radius 2px
                                thead
                                    color #022782
                                    background #EDF0F5
                                    th
                                        padding .06rem 0
                                        background #EDF0F5
                                        text-align center
                                tr
                                    td
                                        padding .06rem 0
                                        border-bottom 0
                                        text-align center
                                    &.el-table__row--striped
                                            background #f8fafd
                            .table-page
                                text-align right
                                margin-top .1rem

</style>
<style>
.thematicLibraryConfig .el-icon-caret-right:before{
    display: none!important;
}
.thematicLibraryConfig .el-tree-node__content{
    height: .36rem;
}
.thematicLibraryConfig .tree-con .is-current>.el-tree-node__content .slot-t-node .leaf-node{
    color: #FE7903;
}
.thematicLibraryConfig .tree-con .is-current>.el-tree-node__content .slot-t-node .leaf-node .iconlingxing{
    color: #FE7903;
}
.thematicLibraryConfig .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
    background-color:#E2EAF0;
}
</style>